<template>
  <items-creator header-title="debug creator" :items-schema="schema">
    <template slot="footer">
      <button
        type="submit"
        class="btn btn-primary btn-block"
        v-on:click="createItem()"
      >
        create
      </button>
    </template>
  </items-creator>
</template>

<script>
import ItemsCreator from "../utility/ItemsCreator/ItemsCreator.vue";

export default {
  components: { ItemsCreator },
  data() {
    return {
      permissions: [],
      schema: {
        apiPath: "",
        data: [
          {
            title: "title",
            key: "title",
            type: "TEXT",
            model: null,
          },
          {
            title: "permissions",
            key: "permissions",
            type: "SELECT2",
            model: null,
            selectOptions: null,
            labelKey: "title",
          },
        ],
      },
    };
  },
  methods: {
    getPermissions() {
      axios
        .get("/resource/permission")
        .then((response) => {
          if (response) {
            console.log(response);
            this.permissions = response.data.data;
            this.schema.data[1].selectOptions = this.permissions;
            // this.myOptions = this.permissions.map((x) => {
            //   return { id: x.id, text: x.title };
            // });
          }
        })
        .catch(function (error) {
          alert(error.response.data.message);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
        });
    },
    createItem(){
        console.log(JSON.stringify(this.schema.data[1].model));
    }
  },
  mounted() {
    this.getPermissions();
  },
};
</script>

<style>
</style>
