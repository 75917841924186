<template>
  <div class="form-group">
    <div>
      <label>{{ title }}</label>
    </div>
    <date-range-picker
      ref="picker"
      opens="left"
      :single-date-picker="true"
      :time-picker="true"
      :time-picker24-hour="true"
      :time-picker-increment="1"
      :locale-data="locale"
      :ranges="ranges"
      v-model="localValue"
      @update="callback"
    >
      <template v-slot:input="value" style="min-width: 350px">
        {{ parseThaiDateTime(value.startDate) }}
      </template>
    </date-range-picker>
    <slot name="tail"></slot>
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: { DateRangePicker },
  props: {
    title: {
      type: String,
      default: "",
    },
    value: Object,
    callback: {
      type: Function,
    },
  },
  computed: {
    localValue: {
      get: function () {
        return this.value;
      },
      set: function (v) {
        this.$emit("input", v);
      },
    },
    ranges: function () {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      return {
        วันนี้: [today, today],
        เมื่อวาน: [this.addDays(today, -1), this.addDays(today, -1)],
      };
    },
  },
  data() {
    return {
      picker: {
        startDate: new Date(),
        endDate: new Date(),
      },
      locale: {
        direction: "ltr",
        format: "mm/dd/yyyy",
        separator: " - ",
        applyLabel: "ตกลง",
        cancelLabel: "ยกเลิก",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"],
        monthNames: [
          "มกราคม",
          "กุมภาพันธ์",
          "มีนาคม",
          "เมษายน",
          "พฤษภาคม",
          "มิถุนายน",
          "กรกฎาคม",
          "สิงหาคม",
          "กันยายน",
          "ตุลาคม",
          "พฤศจิกายน",
          "ธันวาคม",
        ],
        firstDay: 0,
      },
    };
  },
};
</script>

<style></style>
