require("./bootstrap");

window.Vue = require("vue").default;

Vue.config.devtools = true;
Vue.config.debug = true;
Vue.config.silent = false;




Vue.component("auth-login", () => import("./components/auth/Login.vue"));

//layout
Vue.component(
    "dash-nav",
    () => import("./components/dashboard/partials/Nav.vue")
);
Vue.component(
    "dash-side",
    () => import("./components/dashboard/partials/Side.vue")
);
Vue.component(
    "dash-foot",
    () => import("./components/dashboard/partials/Foot.vue")
);

Vue.component(
    "root-view",
    () => import("./components/dashboard/v2/RootView.vue")
);



import VueRouter from "vue-router";
Vue.use(VueRouter);
const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: "/dashboard/debug",
            component: require("./components/dashboard/v2/RootView.vue").default,
            children: [{
                    path: "index",
                    component: require("./components/debug/Debug.vue")
                        .default
                },
                {
                    path: "create",
                    component: require("./components/debug/DebugCreator.vue")
                        .default
                },
            ],
        },
        {
            path: "/dashboard/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [
                {
                    path: "index",
                    component: () => import("./components/dashboard/Index.vue")

                },
                {
                    path: "patch",
                    component: () => import("./components/dashboard/PatchNote.vue")

                },
                {
                    path: "create",
                    component: () => import("./components/debug/DebugCreator.vue")

                },
            ],
        },
        {
            path: "/dashboard/sale/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [{
                    path: "index",
                    component: () => import('./components/dashboard/v2/01_sale/Index.vue'),
                },
                {
                    path: ":chanel/create",
                    component: () => import("./components/dashboard/v2/01_sale/Create.vue")
                },
                {
                    path: ":id/edit",
                    component: () => import("./components/dashboard/v2/01_sale/Edit.vue")
                },
            ]
        },
        {
            path: "/dashboard/buy/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [{
                    path: "monthly/index",
                    component: () => import("./components/dashboard/v2/02_buy/monthly/Index.vue")

                },
                {
                    path: "monthly/create",
                    component: () => import("./components/dashboard/v2/02_buy/monthly/Create.vue")

                },
                {
                    path: "monthly/:id/edit",
                    component: () => import("./components/dashboard/v2/02_buy/monthly/Edit.vue")

                },
                {
                    path: "daily/index",
                    component: () => import("./components/dashboard/v2/02_buy/daily/Index.vue")

                },
                {
                    path: "daily/create",
                    component: () => import("./components/dashboard/v2/02_buy/daily/Create.vue")
                },
                {
                    path: "daily/:id/edit",
                    component: () => import("./components/dashboard/v2/02_buy/daily/Edit.vue")

                },
            ]
        },
        {
            path: "/dashboard/report/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [{
                    path: "revenue",
                    component: () => import("./components/dashboard/v2/03_report/Revenue.vue")

                },
                {
                    path: "seller",
                    component: () => import("./components/dashboard/v2/03_report/Seller.vue")

                },
                {
                    path: "product",
                    component: () => import("./components/dashboard/v2/03_report/ProductSeller.vue")

                },
                {
                    path: "chanel",
                    component: () => import("./components/dashboard/v2/03_report/ChanelSeller.vue")

                },
                {
                    path: "chanel-qty",
                    component: () => import("./components/dashboard/v2/03_report/ChanelQty.vue")

                },
                {
                    path: "receipt",
                    component: () => import("./components/dashboard/v2/03_report/Receipt.vue")

                },
                {
                    path: "stock",
                    component: () => import("./components/dashboard/v3/03_report/Stock.vue")

                },
            ],
        },
        {
            path: "/dashboard/product/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [{
                    path: "index",
                    component: () => import("./components/dashboard/v2/04_product/product/Index.vue")

                },
                {
                    path: "import",
                    component: () => import("./components/dashboard/v2/04_product/product/ImportMultiCategories.vue")

                },
                {
                    path: "import/:category_id",
                    component: () => import("./components/dashboard/v2/04_product/product/ImportSingleCategory.vue")

                },
                {
                    path: "create",
                    component: () => import("./components/dashboard/v2/04_product/product/Create.vue")

                },
                {
                    path: ":id/edit",
                    component: () => import("./components/dashboard/v2/04_product/product/Edit.vue")

                }
            ]
        },
        {
            path: "/dashboard/category/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [{
                    path: "index",
                    component: () => import("./components/dashboard/v2/04_product/category/Index.vue")

                },
                {
                    path: "import",
                    component: () => import("./components/dashboard/v2/04_product/category/Import.vue")

                },
                {
                    path: "index/:id",
                    component: () => import("./components/dashboard/v2/04_product/category/SubIndex.vue")

                },
                {
                    path: "create",
                    component: () => import("./components/dashboard/v2/04_product/category/Create.vue")

                },
                {
                    path: ":id/edit",
                    component: () => import("./components/dashboard/v2/04_product/category/Edit.vue")

                }
            ]
        },
        {
            path: "/dashboard/stock/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [{
                    path: "index",
                    component: () => import("./components/dashboard/v2/05_stock/stock/index.vue")

                },
                {
                    path: "create",
                    component: () => import("./components/dashboard/v2/05_stock/stock/index.vue")

                },
                {
                    path: ":id/edit",
                    component: () => import("./components/dashboard/v2/05_stock/stock/index.vue")

                }
            ]
        },
        {
            path: "/dashboard/order/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [{
                    path: "index",
                    component: () => import("./components/dashboard/v2/05_stock/order/index.vue")

                },
                {
                    path: "create",
                    component: () => import("./components/dashboard/v2/05_stock/order/Create.vue")

                },
                {
                    path: ":id/edit",
                    component: () => import("./components/dashboard/v2/05_stock/stock/index.vue")

                }
            ]
        },
        {
            path: "/dashboard/customer/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [{
                    path: "index",
                    component: () => import("./components/dashboard/v2/06_customer/index.vue")

                },
                {
                    path: "create",
                    component: () => import("./components/dashboard/v2/06_customer/Create.vue")

                },
                {
                    path: ":id/edit",
                    component: () => import("./components/dashboard/v2/06_customer/Edit.vue")

                },
            ]
        },
        {
            path: "/dashboard/month-statement/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [{
                    path: "index",
                    component: () => import("./components/dashboard/v2/07_monthStatement/Index.vue")

                },
                {
                    path: "create",
                    component: () => import("./components/dashboard/v2/07_monthStatement/Create.vue")

                },
                {
                    path: ":id/edit",
                    component: () => import("./components/dashboard/v2/07_monthStatement/Edit.vue")

                },
            ]
        },
        {
            path: "/dashboard/admin/permission/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [{
                    path: "index",
                    component: () => import("./components/dashboard/V2/08_admin/Permission/Index.vue")

                },
                {
                    path: "create",
                    component: () => import("./components/dashboard/V2/08_admin/Permission/Create.vue")

                },
                {
                    path: ":id/edit",
                    component: () => import("./components/dashboard/V2/08_admin/Permission/Edit.vue")

                },
            ]
        },
        {
            path: "/dashboard/admin/role/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [{
                    path: "index",
                    component: () => import("./components/dashboard/V2/08_admin/Role/Index.vue")

                },
                {
                    path: "create",
                    component: () => import("./components/dashboard/V2/08_admin/Role/Create.vue")

                },
                {
                    path: ":id/edit",
                    component: () => import("./components/dashboard/V2/08_admin/Role/Edit.vue")

                },
            ]
        },
        {
            path: "/dashboard/admin/user/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [{
                    path: "index",
                    component: () => import("./components/dashboard/V2/08_admin/User/Index.vue")

                },
                {
                    path: "create",
                    component: () => import("./components/dashboard/V2/08_admin/User/Create.vue")

                },
                {
                    path: ":id/edit",
                    component: () => import("./components/dashboard/V2/08_admin/User/Edit.vue")

                },
            ]
        },
        {
            path: "/dashboard/admin/budget/daily/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [{
                    path: "index",
                    component: () => import("./components/dashboard/V2/08_admin/BudgetDaily/Index.vue")

                },
                {
                    path: "create",
                    component: () => import("./components/dashboard/v2/08_admin/BudgetDaily/Create.vue")

                },
            ]
        },
        {
            path: "/dashboard/admin/budget/monthly/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [{
                    path: "index",
                    component: () => import("./components/dashboard/v2/08_admin/BudgetMonthly/Index.vue")

                },
                {
                    path: "create",
                    component: () => import("./components/dashboard/v2/08_admin/BudgetMonthly/Create.vue")

                },
            ]
        },
        {
            path: "/dashboard/admin/fund/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [{
                    path: "index",
                    component: () => import("./components/dashboard/v2/08_admin/Fund/Index.vue")

                },
                {
                    path: "create",
                    component: () => import("./components/dashboard/v2/08_admin/Fund/Create.vue")

                },
            ]
        },
        {
            path: "/dashboard/admin/app-setting/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [{
                path: "index",
                component: () => import("./components/dashboard/v2/08_admin/AppSetting/Index.vue")

            }, ]
        },
        {
            path: "/dashboard/price-book/",
            component: () => import("./components/dashboard/v2/RootView.vue"),
            children: [{
                    path: "index",
                    component: () => import("./components/dashboard/v2/09_priceBook/Index.vue")

                },
                {
                    path: "import",
                    component: () => import("./components/dashboard/v2/09_priceBook/Import.vue")

                },
            ]
        },
    ],
    linkActiveClass: "active"
});

Vue.component("Select2", () => import("v-select2-component"));

import 'vue-select/dist/vue-select.css';
Vue.component('v-select', () => import("vue-select"))

import Permissions from "./mixins/Permissions";
Vue.mixin(Permissions);
import GlobalMethods from "./mixins/GlobalMethods";
Vue.mixin(GlobalMethods);

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

import {
    Datetime
} from 'vue-datetime'
// // You need a specific loader for CSS files
// import 'vue-datetime/dist/vue-datetime.css'
Vue.use(Datetime)
import {
    Settings
} from 'luxon'
Settings.defaultLocale = 'th'

import store from "./store"



const app = new Vue({
    el: "#app",
    router,
    store: store,
});
