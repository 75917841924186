<template>
  <div class="form-group">
    <label>{{ title }}</label>
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">$</span>
      </div>
      <input
        type="number"
        class="form-control"
        :value="value"
        @input="$emit('input', Number($event.target.value))"
      />
      <div class="input-group-append">
        <span class="input-group-text">.00</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    change: {
      type: Function,
    },
    title: {
      type: String,
    },
    value: {
      type: Number,
    },
  },
};
</script>

<style>
</style>
