<template>
  <input
    :disabled="isDisabled"
    type="number"
    min="0"
    :step="decimalStep"
    class="form-control text-right"
    :value="value"
    @input="$emit('input', Number($event.target.value))"
  />
</template>



<script>
export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: 0.0,
    },
    decimalStep: {
      type: Number,
      default: 1,
      required: true,
    },
  },
};
</script>

<style>
</style>
