<template>
  <div class="row justify-content-center">
    <div class="card col-11 m-2">
      <div class="card-header">
        <h1> {{ headerTitle }}</h1>
      </div>
      <div class="card-body">
          <slot name="before-body"></slot>
          <template v-for="(item,index) in itemsSchema.data">
              <div class="row" :key="index">
                  <template v-if="item.type=='CURRENCY'">
                      <currency-creator class="col-12" v-model="item.model" :title="item.title"></currency-creator>
                  </template>
                  <template v-else-if="item.type=='DATETIME'">
                      <date-time-creator class="col-12" v-model="item.model" :title="item.title"></date-time-creator>
                  </template>
                  <template v-else-if="item.type=='DATE'">
                      <date-time-creator class="col-12" v-model="item.model" :flow="['date']" :title="item.title"></date-time-creator>
                  </template>
                  <template v-else-if="item.type=='DECIMAL'">
                      <decimal-creator class="col-12" v-model="item.model" :title="item.title"></decimal-creator>
                  </template>
                  <template v-else-if="item.type=='INTEGER'">
                      <integer-creator class="col-12" v-model="item.model" :title="item.title"></integer-creator>
                  </template>
                  <template v-else-if="item.type=='SELECT'">
                      <select-creator class="col-12" v-model="item.model" :title="item.title" :selectOptions="item.selectOptions" :labelKey="item.labelKey" @input="item.change"></select-creator>
                  </template>
                  <template v-else-if="item.type=='SELECT2'">
                      <select2-creator class="col-12" v-model="item.model" :title="item.title" :selectOptions="item.selectOptions" :labelKey="item.labelKey" @input="item.change"></select2-creator>
                  </template>
                  <template v-else-if="item.type=='TEXT'">
                      <text-creator class="col-12" v-model="item.model" :title="item.title" @input="item.change"></text-creator>
                  </template>
                  <template v-else-if="item.type=='TEXTARIA'">
                      <text-aria-creator class="col-12" v-model="item.model" :title="item.title"></text-aria-creator>
                  </template>
                  <template v-else-if="item.type=='USERNAME'">
                      <user-name-creator class="col-12" v-model="item.model" :title="item.title"></user-name-creator>
                  </template>
                  <template v-else-if="item.type=='PASSWORD'">
                      <password-creator class="col-12" v-model="item.model" :title="item.title"></password-creator>
                  </template>
                  <template v-else-if="item.type=='EMAIL'">
                      <email-creator class="col-12" v-model="item.model" :title="item.title"></email-creator>
                  </template>
              </div>
          </template>
          <slot name="after-body"></slot>
      </div>
      <div class="card-footer">
          <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import DateTimeCreator from "./partials/DateTimeCreator.vue";
import DecimalCreator from "./partials/DecimalCreator.vue";
import IntegerCreator from "./partials/IntegerCreator.vue";
import CurrencyCreator from "./partials/CurrencyCreator.vue";
import SelectCreator from "./partials/SelectCreator.vue";
import Select2Creator from "./partials/Select2Creator.vue";
import TextCreator from "./partials/TextCreator.vue";
import TextAriaCreator from "./partials/TextAriaCreator.vue";
import UserNameCreator from "./partials/UserNameCreator.vue";
import PasswordCreator from "./partials/PasswordCreator.vue";
import EmailCreator from "./partials/EmailCreator.vue";
export default {
  components: {
    DateTimeCreator,
    DecimalCreator,
    CurrencyCreator,
    IntegerCreator,
    SelectCreator,
    TextCreator,
    TextAriaCreator,
    Select2Creator,
    UserNameCreator,
    PasswordCreator,
    EmailCreator,
  },
  props: {
    headerTitle: {
      type: String,
      default: "Item Creator",
    },
    itemsSchema: {
      type: Object,
      default: null,
    },

  },
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style>
</style>
