<template>
    <div>
        <div class="row justify-content-around mb-3 mt-3">
            <div class="col-6 d-flex justify-content-start">
                <div class="wrap">
                    <select class="form-control" v-model="meta.per_page">
                        <option :value="10">10</option>
                        <option :value="25">25</option>
                        <option :value="50">50</option>
                        <option :value="100">100</option>
                    </select>
                </div>
            </div>
            <div class="col-6 d-flex justify-content-end">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span
                            class="input-group-text"
                            id="inputGroup-sizing-default"
                            >Search</span
                        >
                    </div>
                    <vue-fuse
                        class="form-control"
                        event-name="results"
                        :threshold="0"
                        :list="items"
                        :keys="keysFilter"
                    ></vue-fuse>
                </div>
            </div>
        </div>
        <div class="table-responsive">
            <table
                class="table table-sm table-bordered"
                :class="{ 'table-hover': controls.rowable != null }"
                :style="tableStyle"
            >
                <thead class="clickable">
                    <th
                        v-for="[key, value] in Object.entries(columns)"
                        :key="key"
                        :class="value.headerWrap"
                        :style="value.headerStyle"
                    >
                        <template v-if="value.sortable">
                            <a v-on:click="sortItems(value)">
                                {{ value.label }}
                                <span
                                    class="fas fa-long-arrow-alt-up m-0 text-success"
                                    :class="{
                                        'd-none': value.sortOrder != 1
                                    }"
                                />
                                <span
                                    class="fas fa-long-arrow-alt-down m-0 text-success"
                                    :class="{
                                        'd-none': value.sortOrder != -1
                                    }"
                                />
                            </a>
                        </template>
                        <template v-else>
                            {{ value.label }}
                        </template>
                    </th>
                </thead>
                <tbody>
                    <tr
                        v-for="(row, index) in paginatedResults"
                        :key="index"
                        @click="controls.rowable(row.id)"
                        :class="row.rowWrap"
                    >
                        <td
                            v-for="[key, value] in Object.entries(columns)"
                            :key="key"
                            :class="value.itemsWrap"
                        >
                            <template v-if="key == 'selectable'">
                                <input
                                    type="checkbox"
                                    v-model="row.selected"
                                    :id="'selectable' + index"
                                    :name="'selectable' + index"
                                    @click.stop
                                />
                            </template>
                            <template v-else-if="key == 'index'">
                                {{ index + 1 }}
                            </template>
                            <template v-else-if="key == 'actions'">
                                <a
                                    v-if="value.btns.viewable"
                                    href="javascript:;"
                                    class="btn btn-info btn-sm m-1"
                                    @click.stop
                                    v-on:click="
                                        value.btns.viewable.action(row.id)
                                    "
                                    >{{ value.btns.viewable.label }}</a
                                >
                                <a
                                    v-if="value.btns.updatable"
                                    href="javascript:;"
                                    class="btn btn-warning btn-sm m-1"
                                    @click.stop
                                    v-on:click="
                                        value.btns.updatable.action(row.id)
                                    "
                                    >{{ value.btns.updatable.label }}</a
                                >
                                <a
                                    v-if="value.btns.deletable"
                                    href="javascript:;"
                                    class="btn btn-danger btn-sm m-1"
                                    @click.stop
                                    v-on:click="
                                        value.btns.deletable.action(row.id)
                                    "
                                    >{{ value.btns.deletable.label }}</a
                                >
                            </template>
                            <template v-else>
                                {{
                                    row[key] == null
                                        ? "ไม่มี"
                                        : value.mutation
                                        ? value.mutation(row[key])
                                        : row[key]
                                }}
                            </template>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <slot name="tfoot"></slot>
                </tfoot>
            </table>
        </div>
        <div class="row justify-content-around">
            <div class="col-4">
                Showing {{ meta.from }} to {{ meta.to }} of
                {{ meta.total }} entries
            </div>
            <div class="col-8 d-flex justify-content-end">
                <nav aria-label="Page navigation example">
                    <ul class="pagination">
                        <li
                            class="page-item"
                            :class="{
                                active: meta.link.prevChunk.active,
                                disabled: meta.link.prevChunk.url == null
                            }"
                        >
                            <button
                                class="page-link"
                                v-on:click="
                                    paginatedResults = paginate(
                                        results,
                                        meta.per_page,
                                        meta.current_page,
                                        meta.link.prevChunk.url
                                    )
                                "
                            >
                                <div v-html="meta.link.prevChunk.label"></div>
                            </button>
                        </li>
                        <li
                            v-for="(item, index) in this.meta.link.chunks[
                                this.meta.link.currentChunk
                            ]"
                            :key="index"
                            class="page-item"
                            :class="{
                                active: item.active,
                                disabled: item.url == null
                            }"
                        >
                            <button
                                class="page-link"
                                :key="index"
                                v-on:click="
                                    paginatedResults = paginate(
                                        results,
                                        meta.per_page,
                                        item.url,
                                        meta.link.currentChunk
                                    )
                                "
                            >
                                <div v-html="item.label"></div>
                            </button>
                        </li>
                        <li
                            class="page-item"
                            :class="{
                                active: meta.link.nextChunk.active,
                                disabled: meta.link.nextChunk.url == null
                            }"
                        >
                            <button
                                class="page-link"
                                v-on:click="
                                    paginatedResults = paginate(
                                        results,
                                        meta.per_page,
                                        meta.current_page,
                                        meta.link.nextChunk.url
                                    )
                                "
                            >
                                <div v-html="meta.link.nextChunk.label"></div>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import VueFuse from "vue-fuse";

export default {
    components: { VueFuse },
    props: {
        columns: {
            type: Object,
            required: true
        },
        controls: {
            type: Object
        },
        items: {
            type: Array,
            required: true
        },
        tableStyle: {
            type: String,
            default: "font-size: 16px;"
        },
        defaultSortKey: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            checkedIds: [],
            term: "",
            keysFilter: [],
            results: [],
            paginatedResults: [],
            meta: {
                first: 1,
                last: 1,
                from: -1,
                to: -1,
                total: -1,
                current_page: 1,
                per_page: 25,
                link: {
                    prevChunk: { label: "...", active: false, url: null },
                    nextChunk: { label: "...", active: false, url: null },
                    links: [],
                    orgs: [],
                    chunks: [],
                    currentChunk: 0
                }
            }
        };
    },
    watch: {
        "meta.per_page": function() {
            this.meta.current_page = 1;
            this.paginatedResults = this.paginate(
                this.results,
                this.meta.per_page,
                this.meta.current_page,
                0
            );
        }
    },
    mounted() {
        Object.entries(this.columns).forEach(([key, value]) => {
            if (value.searchable) this.keysFilter.push(key);
        });
        this.$on("results", results => {
            this.results = results;
            this.paginatedResults = this.paginate(
                this.results,
                this.meta.per_page,
                1,
                0
            );
        });
    },
    computed: {},
    methods: {
        sortDefault(column) {
            try {
                let key = this.defaultSortKey;
                this.results.sort((a, b) => {
                    let x = a[key];
                    let y = b[key];
                    return x < y ? -1 : x > y ? 1 : 0;
                });
                column.sortOrder = 0;
            } catch (e) {
                console.log(e);
                column.sortOrder = 0;
            }
        },
        sortAsc(column) {
            this.results.sort((a, b) => {
                let x = a[column.key];
                let y = b[column.key];
                return x < y ? -1 : x > y ? 1 : 0;
            });
            column.sortOrder = -1;
        },
        sortDesc(column) {
            this.results.sort((a, b) => {
                let y = a[column.key];
                let x = b[column.key];
                return x < y ? -1 : x > y ? 1 : 0;
            });
            column.sortOrder = 1;
        },
        sortItems(column) {
            if (column.sortable) {
                Object.entries(this.columns).forEach(([key, value]) => {
                    if (value.key != column.key) value.sortOrder = 0;
                });
                switch (column.sortOrder) {
                    case -1:
                        this.sortDefault(column);
                        break;
                    case 0:
                        this.sortDesc(column);
                        break;
                    case 1:
                        this.sortAsc(column);
                        break;
                }
            }
        },
        paginate(arr, perPage, currentPage, currentChunk) {
            this.meta.total = arr.length;
            let start = (currentPage - 1) * perPage;
            let end = (currentPage - 1) * perPage + perPage;
            end = end > this.meta.total ? this.meta.total : end;
            this.meta.from = start + 1;
            this.meta.to = end;

            this.meta.current_page = currentPage;
            this.meta.per_page = perPage;
            this.meta.last = Math.ceil(this.meta.total / this.meta.per_page);
            this.meta.prev =
                this.meta.current_page == 1 ? null : this.meta.current_page - 1;
            this.meta.next =
                this.meta.current_page == this.meta.last
                    ? null
                    : this.meta.current_page + 1;

            this.meta.link = {
                prevChunk: { label: "...", active: false, url: null },
                nextChunk: { label: "...", active: false, url: null },
                links: [],
                orgs: [],
                chunks: [],
                currentChunk: 0
            };

            for (let i = 1; i <= this.meta.last; i++) {
                this.meta.link.links.push({
                    label: i,
                    active: i == this.meta.current_page,
                    url: i
                });
            }

            let chunk = function(array, size) {
                if (!array.length) {
                    return [];
                }
                let head = array.slice(0, size);
                let tail = array.slice(size);
                return [head, ...chunk(tail, size)];
            };

            this.meta.link.chunks = chunk(this.meta.link.links, 5);

            this.meta.link.currentChunk = currentChunk;
            this.meta.link.prevChunk =
                currentChunk > 0
                    ? {
                          label: "...",
                          active: false,
                          url: currentChunk - 1
                      }
                    : {
                          label: "...",
                          active: false,
                          url: null
                      };
            this.meta.link.nextChunk =
                currentChunk < this.meta.link.chunks.length - 1
                    ? {
                          label: "...",
                          active: false,
                          url: currentChunk + 1
                      }
                    : {
                          label: "...",
                          active: false,
                          url: null
                      };
            return arr.slice(start, end);
        }
    }
};
</script>

<style></style>
