<template>
  <div class="form-group">
    <label>{{ title }}</label>
    <date-range-picker
      ref="picker"
      opens="left"
      :locale-data="locale"
      :ranges="ranges"
      v-model="localValue"
      @update="callback(localValue)"
    >
      <template v-slot:input="value" style="min-width: 350px">
        {{ parseThaiDate(value.startDate) }} -
        {{ parseThaiDate(value.endDate) }}
      </template>
    </date-range-picker>
    <slot name="tail"></slot>
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: { DateRangePicker },
  props: {
    title: {
      type: String,
      default: "",
    },
    value: Object,
    callback: Function,
  },
  computed: {
    localValue: {
      get: function () {
        return this.value;
      },
      set: function (v) {
        v.startDate.setHours(0, 0, 0, 0);
        v.endDate.setHours(0, 0, 0, 0);
        this.$emit("input", v);
      },
    },
    ranges: function () {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let y = today.getFullYear(),
        m = today.getMonth();
      var firstDay = new Date(y, m, 1);
      var lastDay = new Date(y, m + 1, 0);

      return {
        วันนี้: [today, this.addDays(today, +1)],
        เมื่อวาน: [this.addDays(today, -1), today],
        เดือนนี้: [firstDay, lastDay],
        เดือนที่แล้ว: [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        ปีนี้: [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
      };
    },
  },
  data() {
    return {
      picker: {
        startDate: new Date(),
        endDate: new Date(),
      },

      locale: {
        direction: "ltr",
        format: "mm/dd/yyyy",
        separator: " - ",
        applyLabel: "ตกลง",
        cancelLabel: "ยกเลิก",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"],
        monthNames: [
          "มกราคม",
          "กุมภาพันธ์",
          "มีนาคม",
          "เมษายน",
          "พฤษภาคม",
          "มิถุนายน",
          "กรกฎาคม",
          "สิงหาคม",
          "กันยายน",
          "ตุลาคม",
          "พฤศจิกายน",
          "ธันวาคม",
        ],
        firstDay: 0,
      },
    };
  },
};
</script>

<style></style>
