<script>
const moment = require("moment");
require("moment/locale/th");
Array.prototype.groupBy = function (keyFunction) {
  var groups = {};
  this.forEach(function (el) {
    var key = keyFunction(el);
    if (key in groups == false) {
      groups[key] = [];
    }
    groups[key].push(el);
  });
  return Object.keys(groups).map(function (key) {
    return {
      key: key,
      values: groups[key],
    };
  });
};
export default {
  data() {
    return {
      tbXs: "font-size: 12px;",
      tbSm: "font-size: 14px;",
      tbMd: "font-size: 16px;",
      tbLg: "font-size: 18px;",
    };
  },
  methods: {
    $aget() {
      return "not implement";
    },
    parseThaiDateTime(date) {
      let d = new Date(date);
      return (
        d.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }) +
        " " +
        d.toLocaleTimeString("th-TH",{ hour: '2-digit', minute: '2-digit' })
      );
    },
    parseThaiDate(date) {
      let d = new Date(date);
      return d.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    parseThaiMonth(date) {
      let d = new Date(date);
      return;
      d.toLocaleDateString("th-TH", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    },
    parseThaiTime(date) {
      let d = new Date(date);
      return;
      d.toLocaleTimeString("th-TH");
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    addMonths(date, months) {
      var result = new Date(date);
      result.setMonth(result.getMonth() + months);
      return result;
    },
    toSqlDateTime(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    toSqlDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    toSqlTime(date) {
      return moment(date).format("HH:mm:ss");
    },
    toThaiSaleChanel(v) {
      switch (v) {
        case "front":
          return "หน้าร้าน";
        case "webpage":
          return "เพจ";
        case "lazada":
          return "lazada";
        case "shopee":
          return "shopee";
        default:
          return "-error";
      }
    },
    toEngSaleChanel(v) {
      switch (v) {
        case "หน้าร้าน":
          return "front";
        case "เพจ":
          return "webpage";
        case "lazada":
          return "lazada";
        case "shopee":
          return "shopee";
        default:
          return "-error";
      }
    },
    toThaiSaleStatus(v) {
      switch (v) {
        case "wait":
          return "รอตรวจสอบ";
        case "approved":
          return "อนุมัติ";
        case "canceled":
          return "ยกเลิก";
        default:
          return "-error";
      }
    },
    toEngSaleStatus(v) {
      switch (v) {
        case "รอตรวจสอบ":
          return "wait";
        case "อนุมัติ":
          return "approved";
        case "ยกเลิก":
          return "canceled";
        default:
          return "-error";
      }
    },
    toCurrencyString(f, v) {
      var formatter = new Intl.NumberFormat(f, {
        style: "currency",
        currency: "THB",
      });
      return formatter.format(v);
    },
    toThaiCurrencyString(v) {
      var formatter = new Intl.NumberFormat("th-TH", {
        style: "currency",
        currency: "THB",
      });
      return formatter.format(v);
    },
    toEngBudgetType(th) {
      switch (th) {
        case "เข้า":
          return "income";
        case "ออก":
          return "outcome";
        default:
          return "-error";
      }
    },
    toThaiBudgetType(en) {
      switch (en) {
        case "income":
          return "เข้า";
        case "outcome":
          return "ออก";
        default:
          return "-error";
      }
    },
    toThaiBudgetChanel(en) {
      switch (en) {
        case "dialy":
          return "ซื้อรายวัน";
        case "month":
          return "ซื้อสินค้า";
        default:
          return "-error";
      }
    },
  },
};
</script>
