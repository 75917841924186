import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
Vue.config.devtools = true;
const store = new Vuex.Store({
    state: {
        appSetting: null,
        customers: [],
        categories: [],
        products: [],
        stockRemain: [],
    },
    mutations: {
        getAppSetting(state) {
            return new Promise((resolve, reject) => {
                if (state.appSetting == null) {
                    return actions.fetchAppSetting();
                } else {
                    resolve();
                }
            });
        },
        FETCH_APP_SETTING(state, appSetting) {
            state.appSetting = appSetting;
        },
        getCustomers(state) {
            return new Promise((resolve, reject) => {
                if (state.customers.length <= 0) {
                    return actions.fetchCustomers();
                } else {
                    resolve();
                }
            });
        },
        FETCH_CUSTOMERS(state, customers) {
            state.customers = customers;
        },
        getCategories(state) {
            return new Promise((resolve, reject) => {
                if (state.categories.length <= 0) {
                    return actions.fetchCategories();
                } else {
                    resolve();
                }
            });
        },
        FETCH_CATEGORIES(state, categories) {
            state.categories = categories;
        },
        getProducts() {
            return new Promise((resolve, reject) => {
                if (state.products.length <= 0) {
                    return actions.fetchProducts();
                } else {
                    resolve();
                }
            });
        },
        FETCH_PRODUCTS(state, products) {
            state.products = products;
        },
        getStockRemain() {
            return new Promise((resolve, reject) => {
                if (state.stockRemain.length <= 0) {
                    return actions.fetchStockRemain();
                } else {
                    resolve();
                }
            });
        },
        FETCH_STOCK_REMAIN(state, stockRemain) {
            state.stockRemain = stockRemain;
        },
        DECREASE_PRODUCT_REMAIN(state,payload){
            let item = state.products.find(x=>x.id == payload.id);
            item.remain = item.remain - payload.qty;
        },
    },
    actions: {
        fetchAppSetting({
            commit
        }) {
            return new Promise((resolve, reject) => {
                if (this.state.appSetting == null) {
                    axios
                        .get("/resource/admin/app-setting")
                        .then((response) => {
                            if (response) {
                                commit("FETCH_APP_SETTING", response.data);
                                console.log("fresh app_setting data");
                                resolve();
                            }
                        })
                        .catch(function (error) {
                            console.log("Error", error);
                        });
                } else {
                    console.log("old app_setting data");
                    resolve();
                }

            });
        },
        forceAppSetting({
            commit
        }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/resource/admin/app-setting")
                    .then((response) => {
                        if (response) {
                            commit("FETCH_APP_SETTING", response.data);
                            console.log("force app_setting data");
                            resolve();
                        }
                    })
                    .catch(function (error) {
                        console.log("Error", error);
                    });
            });
        },
        fetchCustomers({
            commit
        }) {
            return new Promise((resolve, reject) => {
                if (this.state.customers.length <= 0) {
                    axios
                        .get("/resource/customer")
                        .then((response) => {
                            if (response) {
                                commit("FETCH_CUSTOMERS", response.data.data);
                                console.log("fresh customers data");
                                resolve();
                            }
                        })
                        .catch(function (error) {
                            console.log("error=>" + error);
                            reject(new Error(error));
                        });
                } else {
                    console.log("old customers data");
                    resolve();
                }

            });
        },
        forceCustomers({
            commit
        }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/resource/customer")
                    .then((response) => {
                        if (response) {
                            commit("FETCH_CUSTOMERS", response.data.data);
                            console.log("force customers data");
                            resolve();
                        }
                    })
                    .catch(function (error) {
                        console.log("error=>" + error);
                        reject(new Error(error));
                    });
            });
        },
        fetchCategories({
            commit
        }) {
            return new Promise((resolve, reject) => {
                if (this.state.categories.length <= 0) {
                    axios
                        .get("/resource/category")
                        .then((response) => {
                            if (response) {
                                commit("FETCH_CATEGORIES", response.data.data);
                                console.log("fresh categories data");
                                resolve();
                            }
                        })
                        .catch(function (error) {
                            console.log("error=>" + error);
                            reject(new Error(error));
                        });
                } else {
                    console.log("old categories data");
                    resolve();
                }

            });
        },
        forceCategories({
            commit
        }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/resource/category")
                    .then((response) => {
                        if (response) {
                            commit("FETCH_CATEGORIES", response.data.data);
                            console.log("force data");
                            resolve();
                        }
                    })
                    .catch(function (error) {
                        console.log("error=>" + error);
                        reject(new Error(error));
                    });
            });
        },
        fetchProducts({
            commit
        }) {
            return new Promise((resolve, reject) => {
                if (this.state.products.length <= 0) {
                    axios
                        .get("/resource/product")
                        .then((response) => {
                            if (response) {
                                commit("FETCH_PRODUCTS", response.data.data);
                                console.log("fresh products data");
                                resolve();
                            }
                        })
                        .catch(function (error) {
                            console.log("error=>" + error);
                            reject(new Error(error));
                        });
                } else {
                    console.log("old products data");
                    resolve();
                }

            });
        },
        forceProducts({
            commit
        }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/resource/product")
                    .then((response) => {
                        if (response) {
                            commit("FETCH_PRODUCTS", response.data.data);
                            console.log("force products data");
                            resolve();
                        }
                    })
                    .catch(function (error) {
                        console.log("error=>" + error);
                        reject(new Error(error));
                    });

            });
        },
        fetchStockRemain({
            commit
        }) {
            return new Promise((resolve, reject) => {
                if (this.state.products.length <= 0) {
                    axios
                        .get("/relate/stock")
                        .then((response) => {
                            if (response) {
                                commit("FETCH_STOCK_REMAIN", response.data.data);
                                console.log("fresh stock remain data");
                                resolve();
                            }
                        })
                        .catch(function (error) {
                            console.log("error=>" + error);
                            reject(new Error(error));
                        });
                } else {
                    console.log("old products data");
                    resolve();
                }

            });
        },
        forceStockRemain({
            commit
        }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/relate/stock")
                    .then((response) => {
                        if (response) {
                            commit("FETCH_STOCK_REMAIN", response.data.data);
                            console.log("force stock remain data");
                            resolve();
                        }
                    })
                    .catch(function (error) {
                        console.log("error=>" + error);
                        reject(new Error(error));
                    });

            });
        },
        // decreaseProductRemain({commit},payload){
        //     commit('DECREASE_PRODUCT_REMAIN', payload.id, payload.qty)
        // },
        decreaseProductRemain: ({ commit }, {id, qty}) => commit('DECREASE_PRODUCT_REMAIN', {id, qty}),
    }
})

export default store
