<template>
  <table class="table table-sm" :style="tableStyle">
    <thead>
      <th
        v-for="[key, value] in Object.entries(columns)"
        :key="key"
        :class="value.headerWrap"
        :style="value.headerStyle"
      >
        {{ value.label }}
      </th>
    </thead>
    <tbody>
      <tr v-for="(row, index) in items" :key="index">
        <td
          v-for="[key, value] in Object.entries(columns)"
          :key="key"
          :class="value.itemsWrap"
        >
          <template v-if="key == 'index'">
            <button class="btn btn-sm btn-warning" @click="deleteItem(index)">
              <i class="far fa-trash-alt"></i>
            </button>
            {{ index + 1 }}
          </template>
          <template v-else>
            <template v-if="value.editable">
              <div v-if="value.editable == Number">
                <!-- oninput="this.value = Math.round(this.value);" -->
                <!-- <input
                  :disabled="value.isDisable?value.isDisable(index):false"
                  type="number"
                  min="0"
                  step="0.01"
                  class="form-control text-right"
                  v-model.number="row[key]"
                  @input="
                    value.callback({ index: index, key: key, value: row[key] })
                  "
                /> -->
                <number-input
                  v-model.number="row[key]"
                  :decimalStep="value.decimalStep"
                  :isDisabled="value.isDisable ? value.isDisable(index) : false"
                  @input="
                    value.callback({ index: index, key: key, value: row[key] })
                  "
                ></number-input>
              </div>
            </template>
            <template v-else>
              {{
                row[key] == null
                  ? "ไม่มี"
                  : value.mutation
                  ? value.mutation(row[key])
                  : row[key]
              }}
            </template>
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    columns: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    deleteItems: {
      type: Array,
      required: true,
    },
    tableStyle: {
      type: String,
      default: "font-size: 16px;",
    },
  },
  computed: {},
  methods: {
    deleteItem(index) {
      this.deleteItems.push(this.items[index]);
      this.items.splice(index, 1);
    },
  },
};
</script>

<style>
</style>
