<template>
  <div class="container-fluid p-6">
    <div class="row">
      <button class="btn btn-primary" @click="test">Local Button</button>
    </div>
    <div class="large-12 medium-12 small-12 cell">
      <label
        >File
        <input
          type="file"
          id="file"
          ref="file"
          v-on:change="handleFileUpload()"
        />
      </label>
      <button v-on:click="submitFile()">Submit</button>
    </div>
    <div class="row">
      <custom-modal ref="modal1">
        <template name="header"> </template>
        <template name="body"> </template>
      </custom-modal>
    </div>
  </div>
</template>


<script>
import CustomModal from "../utility/CustomModal.vue";
export default {
  data() {
    return {
      file: {},
    };
  },
  mounted() {},
  methods: {
    test() {
      axios({
        url: "/util/pdf/test",
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", "file.pdf");
        // document.body.appendChild(link);
        // link.click();
      });
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    submitFile() {
      let formData = new FormData();
      formData.append("file", this.file);
      axios
        .post("/debug/file-upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response) {
            console.log(response);
          }
        })
        .catch(function (error) {
          console.log("Error", error);
        });
    },
  },
};
</script>
