<template>
  <div class="form-group">
    <div>
      <label>{{ title }}</label>
    </div>
    <date-range-picker
      ref="picker"
      opens="left"
      :single-date-picker="false"
      :time-picker="false"
      :locale-data="locale"
      :ranges="ranges"
      v-model="localValue"
      @update="callback"
    >
      <template v-slot:input="value" style="min-width: 350px">
        {{ parseThaiDateTime(value.startDate) }} -
        {{ parseThaiDateTime(value.endDate) }}
      </template>
    </date-range-picker>
    <slot name="tail"></slot>
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: { DateRangePicker },
  props: {
    title: {
      type: String,
      default: "",
    },
    value: Object,
    callback: {
      type: Function,
    },
  },
  computed: {
    localValue: {
      get: function () {
        return this.value;
      },
      set: function (v) {
        this.$emit("input", v);
      },
    },
    ranges: function () {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let monthStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
      let monthEndDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      let lastMonthStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      let lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
      let lastTreeMonthStartDate = new Date(today.getFullYear(), today.getMonth() - 3, 1);
      let lastTreeMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
      let yearStartDate = new Date(today.getFullYear(), 0, 1);
      let yearEndDate = new Date(today.getFullYear()+1, 0, 0,);
      let lastYearStartDate = new Date(today.getFullYear()-1, 0, 1);
      let lastYearEndDate = new Date(today.getFullYear(), 0, 0);



      return {
        ปีที่แล้ว: [lastYearStartDate, lastYearEndDate],
        ปีนี้: [yearStartDate, yearEndDate],
        ย้อนหลังสามเดือน: [lastTreeMonthStartDate,lastTreeMonthEndDate],
        เดือนที่แล้ว: [lastMonthStartDate,lastMonthEndDate],
        เดือนนี้: [monthStartDate, monthEndDate],
      };
    },
  },
  data() {
    return {
      picker: {
        startDate: new Date(),
        endDate: new Date(),
      },
      locale: {
        direction: "ltr",
        format: "mm/dd/yyyy",
        separator: " - ",
        applyLabel: "ตกลง",
        cancelLabel: "ยกเลิก",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"],
        monthNames: [
          "มกราคม",
          "กุมภาพันธ์",
          "มีนาคม",
          "เมษายน",
          "พฤษภาคม",
          "มิถุนายน",
          "กรกฎาคม",
          "สิงหาคม",
          "กันยายน",
          "ตุลาคม",
          "พฤศจิกายน",
          "ธันวาคม",
        ],
        firstDay: 0,
      },
    };
  },
};
</script>

<style></style>
