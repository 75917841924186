<template>
  <router-view></router-view>
</template>

<script>
// Vue.component(
//   "admin-lara-table",
//   require("../../utility/AdminLaraTable.vue").default
// );
// Vue.component(
//   "admin-lara-table-collapse",
//   require("../../utility/AdminLaraTableCollapse.vue").default
// );
// Vue.component(
//   "admin-edit-table",
//   require("../../utility/AdminEditTable.vue").default
// );
// Vue.component(
//   "items-creator-json",
//   require("../../utility/ItemsCreator/ItemsCreatorJson.vue").default
// );
// Vue.component(
//   "items-creator",
//   require("../../utility/ItemsCreator/ItemsCreator.vue").default
// );

//utility
//input
// Vue.component(
//   "TextCreator",
//   require("../../utility/ItemsCreator/partials/TextCreator.vue").default
// );
// Vue.component(
//   "TextAriaCreator",
//   require("../../utility/ItemsCreator/partials/TextAriaCreator.vue").default
// );
// Vue.component(
//   "SelectCreator",
//   require("../../utility/ItemsCreator/partials/SelectCreator.vue").default
// );
Vue.component("NumberInput", require("../../utility/NumberInput.vue").default);
Vue.component(
  "DateTimeCreator",
  require("../../utility/ItemsCreator/partials/DateTimeCreator.vue").default
);
Vue.component(
  "DateRangeCreator",
  require("../../utility/ItemsCreator/partials/DateRangeCreator.vue").default
);
Vue.component(
  "CustomDateRange",
  require("../../utility/CustomDateRange.vue").default
);
Vue.component("CustomDate", require("../../utility/CustomDate.vue").default);
Vue.component("CustomDateNoTitle", require("../../utility/CustomDateNoTitle.vue").default);
Vue.component("CustomMonth", require("../../utility/CustomMonth.vue").default);
//table
Vue.component("AdminTable", require("../../utility/AdminTable.vue").default);
Vue.component("AdminSortTable", require("../../utility/AdminSortTable.vue").default);
Vue.component(
  "AdminViewTable",
  require("../../utility/AdminViewTable.vue").default
);
Vue.component(
  "AdminEditTable",
  require("../../utility/AdminEditTable.vue").default
);
//component
Vue.component("CustomModal", require("../../utility/CustomModal.vue").default);
Vue.component(
  "WaitingModal",
  require("../../utility/WaitingModal.vue").default
);
//utility

//helper
// Vue.component(
//   "h-category-selector",
//   require("../../helper/CategorySelector.vue").default
// );
// Vue.component(
//   "h-category-selector-2",
//   require("../../helper/CategorySelector2.vue").default
// );
// Vue.component(
//   "h-main-category-selector",
//   require("../../helper/CategorySelector.vue").default
// );
// Vue.component(
//   "h-product-selector",
//   require("../../helper/ProductSelector.vue").default
// );
// Vue.component(
//   "h-product-filter-selector",
//   require("../../helper/ProductFilterSelector.vue").default
// );
// Vue.component(
//   "customer-selector",
//   require("../../helper/CustomerSelector.vue").default
// );
// Vue.component(
//   "sale-product-selector",
//   require("../../helper/SaleProductSelector.vue").default
// );
// Vue.component(
//   "sale-cost-selector",
//   require("../../helper/SaleCostSelector.vue").default
// );
// Vue.component(
//   "operating-cost-selector",
//   require("../../helper/OperatingCostSelector.vue").default
// );
// Vue.component(
//   "order-product-selector",
//   require("../../helper/OrderProductSelector.vue").default
// );
// Vue.component(
//   "sale-item-selector",
//   require("../../helper/SaleItemSelector.vue").default
// );
// Vue.component(
//   "customer-add-modal",
//   require("../../helper/CustomerAddModal.vue").default
// );
//helper

export default {
  methods: {
    firePopup() {
      let vmSwal = this.$swal;
      let vmSaleModal = this.$refs.saleModal;
      this.$swal
        .fire({
          title: "ยืนยัน?",
          text: "ลบข้อมูล!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "ยืนยัน!",
          cancelButtonText: "ยกเลิก",
        })
        .then((result) => {
          if (result.isConfirmed) {
          }
        });
    },
  },
};
</script>

<style>
.card-header-primary {
  background-color: #001f3f;
  color: white;
}
.card-header-secondary {
  background-color: #6c757d;
  color: white;
}
.blockquote-danger {
  border-left-color: #e3342f;
}
.blockquote-warning {
  border-left-color: #ffed4a;
}
.blockquote-success {
  border-left-color: #38c172;
}
.blockquote-kbank {
  border-left-color: #00a950;
}
.blockquote-scb {
  border-left-color: #7a58bf;
}
.blockquote-income {
  border-left-color: #007bff;
}
.blockquote-cost {
  border-left-color: #f67941;
}
.blockquote-vat {
  border-left-color: #a1cde4;
}
.blockquote-profit {
  border-left-color: #00a950;
}
.blockquote-qty {
  border-left-color: #a83e41;
}
.blockquote-front {
  border-left-color: #888888;
}
.blockquote-webpage {
  border-left-color: #1876f2;
}
.blockquote-lazada {
  border-left-color: #12166e;
}
.blockquote-shopee {
  border-left-color: #f94e2f;
}
.qty-color {
  background-color: #a83e41;
  color: white;
}
.scb-color {
  background-color: #7a58bf;
  color: white;
}
.kbank-color {
  background-color: #00a950;
  color: white;
}
.fund-color {
  background-color: #ffc107;
  color: #1f2d3d;
}
.front-primary {
  background-color: #888888;
  color: white;
}
.webpage-primary {
  background-color: #1876f2;
  color: white;
}
.shopee-primary {
  background-color: #f94e2f;
  color: white;
}
.lazada-primary {
  background-color: #12166e;
  color: white;
}
.admin-primary {
  background-color: #17a2b8;
  color: white;
}
.clickable{
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
