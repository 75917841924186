<template>
  <div class="form-group">
    <label>{{ title }}</label>
    <datepicker
      :bootstrap-styling="true"
      wrapper-class="input-group"
      input-class="form-control float-right bg-white"
      id="reservationtime"
      :value="value"
      :language="th"
      @input="change"
    >
      <div slot="afterDateInput" class="input-group-append">
        <span class="input-group-text">
          <i class="far fa-calendar-alt" />
        </span>
      </div>
    </datepicker>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { en, th } from "vuejs-datepicker/dist/locale";
export default {
  components: {
    Datepicker,
  },
  props: {
    value: Date,
    change: {
      type: Function,
    },
    popupTitle: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      en: en,
      th: th,
    };
  },
  mounted() {},
  methods: {},
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>

<style>
</style>
