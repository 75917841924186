<template>
  <div>
    <button type="button" class="btn btn-primary d-none" @click="toggleModal">My Modal</button>
    <div
      ref="modal"
      class="modal fade"
      :class="{ show, 'd-block': active }"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <slot name="header"></slot>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="toggleModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
    <div v-if="active" class="modal-backdrop fade show"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: false,
      show: false,
    };
  },
  methods: {
    toggleModal() {
      const body = document.querySelector("body");
      this.active = !this.active;
      this.show = this.active;
      this.active
        ? body.classList.add("modal-open")
        : body.classList.remove("modal-open");
    //   setTimeout(() => (this.show = !this.show), 10);
    },
    showModal() {
      const body = document.querySelector("body");
      this.active = true;
      this.show = true;
      body.classList.add("modal-open");
    //   setTimeout(() => (this.show = !this.show), 10);
    },
    hideModal() {
      const body = document.querySelector("body");
      this.active = false;
      this.show = false;
      body.classList.remove("modal-open");
    },
  },
};
</script>
