<template>
  <div class="form-group">
    <label>{{ title }}</label>
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text"><i class="fas fa-envelope"></i></span>
      </div>
      <input
        type="email"
        class="form-control"
        placeholder="email"
        :value="value"
        @input="$emit('input', $event.target.value)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    change: {
      type: Function,
    },
    title: {
      type: String,
    },
    value: {
      type: String,
    },
  },
};
</script>

<style>
</style>
