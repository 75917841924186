<template>
  <div class="form-group">
    <label>{{ title }}</label>
    <datetime
      class="input-group"
      input-class="form-control float-right"
      id="reservationtime"
      @input="change"
      v-model="selected"
      :value="value"
      :title="popupTitle"
      input-id="startDate"
      value-zone="UTC+7"
      zone="Asia/Bangkok"
      :flow="flow"
      type="datetime"
    >
      <template slot="before">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <i class="far fa-clock" />
          </span>
        </div>
      </template>
    </datetime>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    flow:{
      type: Array,
      default:()=>['year', 'date', 'time'],
    },
    change: {
      type: Function,
    },
    popupTitle: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  mounted() {},
  methods: {},
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>

<style>
</style>
