<template>
  <div class="form-group">
    <label>{{ title }}</label>
    <textarea
      class="form-control"
      rows="3"
      :value="value"
      @input="$emit('input', $event.target.value)"
    ></textarea>
  </div>
</template>

<script>
export default {
  props: {
    change: {
      type: Function,
    },
    title: {
      type: String,
    },
    value: {
      type: String,
    },
  },
};
</script>

<style>
</style>
