<template>
  <table class="table table-sm" :style="tableStyle">
    <thead>
      <th
        v-for="[key, value] in Object.entries(columns)"
        :key="key"
        :class="value.headerWrap"
        :style="value.headerStyle"
        :colspan="value.span"
      >
        {{ value.label }}
      </th>
    </thead>
    <tbody>
      <tr v-for="(row, index) in items" :key="index">
        <td
          v-for="[key, value] in Object.entries(columns)"
          :key="key"
          :class="value.itemsWrap"
          :colspan="value.span"
        >
          <template v-if="key == 'index'">
            {{ index + 1 }}
          </template>
          <template v-else>
            {{
              row[key] == null
                ? "ไม่มี"
                : value.mutation
                ? value.mutation(row[key])
                : row[key]
            }}
          </template>
        </td>
      </tr>
    </tbody>
    <tfoot>
         <slot name="tfoot"></slot>
    </tfoot>
  </table>
</template>

<script>
export default {
  //   props: ["columns", "items"],
  props: {
    columns: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    tableStyle: {
      type: String,
      default: "font-size: 16px;",
    },
  },
};
</script>

<style>
</style>
