<template>
  <div class="form-group">
    <label>{{ title }}</label>
    <div class="input-group">
      <input type="number" oninput="this.value = Math.round(this.value);" min="0" step="1" class="form-control" :value="value"  @input="$emit('input',Number($event.target.value))"/>
      <div class="input-group-append">
        <span class="input-group-text">Int</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    change: {
      type: Function,
    },
    title: {
      type: String,
    },
    value: {
      type: Number,
    },
  },
};
</script>

<style>
</style>
