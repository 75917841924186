<template>
  <div class="form-group">
    <label>{{ title }}</label>
    <div class="input-group">
      <template v-if="selectOptions != null">
        <select
          v-if="selectOptions.length > 0"
          class="form-control"
          v-model="selected"
          :value="value"
        >
          <option
            v-for="(item, index) in selectOptions"
            :key="index"
            :value="item"
          >
            {{ item[labelKey] }}
          </option>
        </select>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    title: {
      type: String,
    },
    selectOptions: {
      type: Array,
      default: () => [],
    },
    labelKey: String,
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  watch: {
    // selectOptions(oldOne, newOne) {
    //   console.log("oldOne=>" + oldOne);
    //   console.log("newOne=>" + newOne);
    // if(this.selectOptions.length>0)this.selected = this.selectOptions[0];
    // },
  },
  created() {},
};
</script>

<style>
</style>
