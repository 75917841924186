<template>
  <div>
    <div
      ref="modal"
      class="modal fade"
      :class="{ show, 'd-block': active }"
      tabindex="-1"
      role="dialog"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="spinner-grow" role="status">
          </div>
        </div>
      </div>
    </div>
    <div v-if="active" class="modal-backdrop fade show"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: false,
      show: false,
    };
  },
  methods: {
    toggleModal() {
      const body = document.querySelector("body");
      this.active = !this.active;
      this.show = this.active;
      this.active
        ? body.classList.add("modal-open")
        : body.classList.remove("modal-open");
      //   setTimeout(() => (this.show = !this.show), 10);
    },
    showModal() {
      const body = document.querySelector("body");
      this.active = true;
      this.show = true;
      body.classList.add("modal-open");
      //   setTimeout(() => (this.show = !this.show), 10);
    },
    hideModal() {
      const body = document.querySelector("body");
      this.active = false;
      this.show = false;
      body.classList.remove("modal-open");
    },
  },
};
</script>
